<template>
  <div :class="[
  'cell']" :style="`top:${10*row}px; left:${10*col}px;`">
  </div>
</template>

<script>

export default {
  name: 'Cell',
  props: {
    row: {
      type: Number,
      default: -1,
    },
    col: {
      type: Number,
      default: -1,
    },
    isAlive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.cell {
  position: absolute;
  background: black;
  width: 10px;
  height: 10px;
  /* border: 1px solid rgba(0, 0, 0, 0.26); */
}
</style>

/* eslint-disable max-len */
const cannon = [[1, 5], [2, 5], [1, 6], [2, 6], [13, 3], [14, 3], [12, 4], [16, 4], [11, 5], [17, 5], [11, 6], [15, 6], [17, 6], [18, 6], [11, 7], [17, 7], [12, 8], [16, 8], [13, 9], [14, 9], [25, 1], [23, 2], [25, 2], [21, 3], [22, 3], [21, 4], [22, 4], [21, 5], [22, 5], [23, 6], [25, 6], [25, 7], [35, 3], [36, 3], [35, 4], [36, 4]];
const pulsar = [[55, 4], [55, 5], [55, 6], [55, 10], [55, 11], [55, 12], [60, 4], [60, 5], [60, 6], [60, 10], [60, 11], [60, 12], [62, 4], [62, 5], [62, 6], [62, 10], [62, 11], [62, 12], [67, 4], [67, 5], [67, 6], [67, 10], [67, 11], [67, 12], [57, 2], [58, 2], [59, 2], [63, 2], [64, 2], [65, 2], [57, 7], [58, 7], [59, 7], [63, 7], [64, 7], [65, 7], [57, 9], [58, 9], [59, 9], [63, 9], [64, 9], [65, 9], [57, 14], [58, 14], [59, 14], [63, 14], [64, 14], [65, 14]];
const pentadecathlon = [[59, 27 - 1], [64, 27 - 1], [57, 28 - 1], [58, 28 - 1], [60, 28 - 1], [61, 28 - 1], [62, 28 - 1], [63, 28 - 1], [65, 28 - 1], [66, 28 - 1], [59, 29 - 1], [64, 29 - 1]];
const beacon = [[58 - 4, 38 - 4], [64 - 4, 44 - 4], [65 - 4, 44 - 4], [64 - 4, 45 - 4], [67 - 4, 46 - 4], [66 - 4, 47 - 4], [67 - 4, 47 - 4]];
const toad = [[45 + 16, 26 + 29], [46 + 16, 26 + 29], [47 + 16, 26 + 29], [44 + 16, 27 + 29], [45 + 16, 27 + 29], [46 + 16, 27 + 29]];
const blinker = [[45 + 15, 56 + 13], [46 + 15, 56 + 13], [47 + 15, 56 + 13]];
const heavyWeightSpaceship1 = [[196, 5], [197, 5], [194, 6], [199, 6], [193, 7], [193, 8], [199, 8], [193, 9], [194, 9], [195, 9], [196, 9], [197, 9], [198, 9]];
// const heavyWeightSpaceship2 = [[221, 10], [222, 10], [219, 11], [224, 11], [218, 12], [218, 13], [224, 13], [218, 14], [219, 14], [220, 14], [221, 14], [222, 14], [223, 14]];
// const heavyWeightSpaceship3 = [[270, 1], [271, 1], [268, 2], [273, 2], [267, 3], [267, 4], [273, 4], [267, 5], [268, 5], [269, 5], [270, 5], [271, 5], [272, 5]];

// const glider = [[3, 1], [4, 2], [2, 3], [3, 3], [4, 3]];
// const fullConfig = [[1,5],[2,5],[1,6],[2,6],[13,3],[14,3],[12,4],[16,4],[11,5],[17,5],[11,6],[15,6],[17,6],[18,6],[11,7],[17,7],[12,8],[16,8],[13,9],[14,9],[25,1],[23,2],[25,2],[21,3],[22,3],[21,4],[22,4],[21,5],[22,5],[23,6],[25,6],[25,7],[35,3],[36,3],[35,4],[36,4],[55,4],[55,5],[55,6],[55,10],[55,11],[55,12],[60,4],[60,5],[60,6],[60,10],[60,11],[60,12],[62,4],[62,5],[62,6],[62,10],[62,11],[62,12],[67,4],[67,5],[67,6],[67,10],[67,11],[67,12],[57,2],[58,2],[59,2],[63,2],[64,2],[65,2],[57,7],[58,7],[59,7],[63,7],[64,7],[65,7],[57,9],[58,9],[59,9],[63,9],[64,9],[65,9],[57,14],[58,14],[59,14],[63,14],[64,14],[65,14],[59,26],[64,26],[57,27],[58,27],[60,27],[61,27],[62,27],[63,27],[65,27],[66,27],[59,28],[64,28],[54,34],[60,40],[61,40],[60,41],[63,42],[62,43],[63,43],[61,55],[62,55],[63,55],[60,56],[61,56],[62,56],[60,69],[61,69],[62,69],[196,5],[197,5],[194,6],[199,6],[193,7],[193,8],[199,8],[193,9],[194,9],[195,9],[196,9],[197,9],[198,9]]
const config = [
  ...cannon,
  ...pulsar,
  ...pentadecathlon,
  ...beacon,
  ...toad,
  ...blinker,
  ...heavyWeightSpaceship1,
  // ...glider,
  // ...fullConfig,
];
console.log({ config });
export default config;

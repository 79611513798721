<template>
  <div id="app">
    <Space/>
  </div>
</template>

<script>
import Space from './components/Space.vue';

export default {
  name: 'App',
  components: {
    Space,
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100wv;
  height: 100vh;
  padding: 1rem;
}
</style>

<template>
<div class="container">
  <div class="space">
    <Cell v-for="(cell, index) in space" :row="cell.y" :col="cell.x" :key="index" />
  </div>
</div>
</template>

<script>
import config from '../initConfig';
import Coordinates from '../CellUtil';
import Cell from './Cell.vue';

export default {
  name: 'Space',
  components: {
    Cell,
  },
  data() {
    return {
      // eslint-disable-next-line
      space: this.getSpace( config ),
    };
  },
  mounted() {
    // this.space = this.space.map((cell) => new Coordinates(cell.x + 40, cell.y + 110));
    console.log(`[${this.space.map((cell) => `[${cell.x},${cell.y}]`)}]`);
    setInterval(() => {
      this.tick();
    }, 0);
  },
  methods: {
    getSpace(configuration) {
      return configuration.map((aliveCell) => new Coordinates(aliveCell[0], aliveCell[1]));
    },
    isAlive(row, col) {
      let isAlive = false;
      this.space.forEach((cellCoordinates) => {
        if (row === cellCoordinates.x && col === cellCoordinates.y) {
          isAlive = true;
        }
      });
      return isAlive;
    },
    hasCell(cell, cells) {
      let hasCell = false;
      cells.forEach((c) => {
        if (c.x === cell.x && c.y === cell.y) {
          hasCell = true;
        }
      });
      return hasCell;
    },
    tick() {
      // eslint-disable-next-line
      this.space = this.getCellsAliveAfterTick()
      // .filter((cell) => cell.x > -25 && cell.x < 100 && cell.y > -25 && cell.y < 100);
    },
    getCellsAliveAfterTick() {
      const cellsPotentiallyAliveAfterTick = this.getCellsPotentiallyAliveAfterTick();
      // eslint-disable-next-line
      const cellsAliveAfterTick = cellsPotentiallyAliveAfterTick.filter((cell) => this.willBeAlive(this.isAlive(cell.x, cell.y), this.getAliveNeighbors(cell).length));

      return this.removeDuplicateCells(cellsAliveAfterTick);
    },
    removeDuplicateCells(cells) {
      const uniqueCells = [];
      cells.forEach((cell) => {
        if (!this.hasCell(cell, uniqueCells)) {
          uniqueCells.push(cell);
        }
      });
      return uniqueCells;
    },
    getCellsPotentiallyAliveAfterTick() {
      const allNeighbors = this.space.map((cell) => this.getNeighbors(cell));
      const cellsPotentiallyAliveAfterTick = [...this.space];
      allNeighbors.forEach((cellNeighbors) => cellNeighbors.forEach((neighbor) => {
        const coor = new Coordinates(neighbor.x, neighbor.y);
        cellsPotentiallyAliveAfterTick.push(coor);
      }));
      return cellsPotentiallyAliveAfterTick;
    },
    willBeAlive(isAlive, noOfNeighbors) {
      if (!isAlive && noOfNeighbors === 3) {
        return true;
      } if (!isAlive) {
        return false;
      } if ([2, 3].includes(noOfNeighbors)) {
        return true;
      }
      return false;
    },
    getAliveNeighbors(cell) {
      const neighbors = this.getNeighbors(cell);
      return neighbors.filter((neighbor) => neighbor.isAlive);
    },
    getNeighbors(cell) {
      const neighbors = [];
      const { x, y } = cell;
      // diagonal
      neighbors.push({ x: x - 1, y: y - 1, isAlive: this.isAlive(x - 1, y - 1) });
      neighbors.push({ x: x - 1, y: y + 1, isAlive: this.isAlive(x - 1, y + 1) });
      neighbors.push({ x: x + 1, y: y + 1, isAlive: this.isAlive(x + 1, y + 1) });
      neighbors.push({ x: x + 1, y: y - 1, isAlive: this.isAlive(x + 1, y - 1) });
      // orthogonal
      neighbors.push({ x: x - 1, y, isAlive: this.isAlive(x - 1, y) });
      neighbors.push({ x, y: y + 1, isAlive: this.isAlive(x, y + 1) });
      neighbors.push({ x: x + 1, y, isAlive: this.isAlive(x + 1, y) });
      neighbors.push({ x, y: y - 1, isAlive: this.isAlive(x, y - 1) });
      return neighbors;
    },

  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
.row {
  display:flex;
  flex-direction: column;
}
.col {
  display:flex;
}
</style>
